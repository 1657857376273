import { Experiments } from '@wix/yoshi-flow-editor';

export async function getSiteOwnerExperiments(
  ownerId: string,
): Promise<Experiments> {
  const experiments = new Experiments({
    useNewApi: true,
    requestContext: {
      forSiteOwner: {
        siteOwnerId: ownerId,
        loggedInUserId: ownerId,
      },
    },
  });
  await experiments.load('vod');
  return experiments;
}
